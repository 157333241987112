@keyframes twitch {
  0% {
    transform: rotate(.5deg);
  }
  50% {
    transform: rotate(-.5deg);
  }
  100% {
    transform: rotate(.5deg);
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(4deg);
  }
  50% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(4deg);
  }
}

@keyframes curious-tilt {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(4deg);
  }
  50% {
    transform: rotate(-4deg);
  }
  75% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes slide-up-left {
  0% {
    left: 20px;
    top: 800px;
    transform: rotate(75deg);
  }
  70% {
    left: 20px;
    top: 800px;
    transform: rotate(75deg);
  }
  100% {
    left: 0;
    top: 0;
    transform: rotate(-8deg);
  }
}

@keyframes slide-up-right {
  0% {
    left: -20px;
    top: 800px;
    transform: rotate(75deg);
  }
  80% {
    left: -20px;
    top: 800px;
    transform: rotate(75deg);
  }
  100% {
    left: 0;
    top: 0;
    transform: rotate(8deg);
  }
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    transform-origin: 50% 50%;
  }
  50% {
    transform: scale(0.97);
    transform-origin: 50% 50%;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 50%;
  }
}

@keyframes spin-in {
  from {
    transform: scale(0);
    transform-origin: 50% 50%;
  }
  to {
    transform: scale(1);
    transform-origin: 0 0;
  }
}

@keyframes spin-out {
  from {
    transform: scale(1);
    transform-origin: 50% 50%;
  }
  to {
    transform: scale(0);
    transform-origin: 0% 0%;
  }
}